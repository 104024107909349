import $ from "jquery";
import _ from 'lodash';
import moment from "moment";
import {formatDecimalNotRound, getDeviceInfo} from "./helper";

let m = [
	{
		index: 1,
		min: -90,
		max: -55,
		class: "rank-1"
	}, {
		index: 2,
		min: -54,
		max: -19,
		class: "rank-2"
	}, {
		index: 3,
		min: -18,
		max: 17,
		class: "rank-3"
	}, {
		index: 4,
		min: 18,
		max: 53,
		class: "rank-4"
	}, {
		index: 5,
		min: 54,
		max: 90,
		class: "rank-5"
	}
];
let p = 3;
let t = {
	reconnect: !1,
	gaugeMeter: null,
	gaugeMeterOs: null,
	gaugeMeterSu: null,
	gaugeMeterMa: null,
	oscillators: {
		items: {
			relativeStrengthIndex: p,
			stochasticOscillator: p,
			commodityChannelIndex: p,
			awesomeOscillator: p,
			momentum: p,
			macd: p,
			stochasticRSIFast: p,
			williamsPercentRange: p,
			ultimateOscillator: p
		},
		buy: 0,
		sell: 0,
		neutral: 0,
		meter: {
			numberValue: 0,
			textValue: ""
		}
	},
	summary: {
		buy: 0,
		sell: 0,
		neutral: 0,
		meter: {
			numberValue: 0,
			textValue: ""
		}
	},
	movingAverages: {
		items: {
			exponentialMovingAverage5: p,
			exponentialMovingAverage10: p,
			exponentialMovingAverage20: p,
			exponentialMovingAverage30: p,
			exponentialMovingAverage50: p,
			exponentialMovingAverage100: p,
			simpleMovingAverage5: p,
			simpleMovingAverage10: p,
			simpleMovingAverage20: p,
			simpleMovingAverage30: p,
			simpleMovingAverage50: p,
			simpleMovingAverage100: p
		},
		buy: 0,
		sell: 0,
		neutral: 0,
		meter: {
			numberValue: 0,
			textValue: ""
		}
	}
};
const convertTextValueMeter = (t) => {
	return t >= -90 && t <= -54 ? "Strong sell" : t > -54 && t <= -18 ? "Sell" : t > -18 && t <= 17 ? "Neutral" : t > 17 && t <= 53 ? "Buy" : t > 53 ? "Strong Buy" : null
}
Math.easeOutBounce = function (t) {
	return t < 1 / 2.75 ? 7.5625 * t * t : t < 2 / 2.75 ? 7.5625 * (t -= 1.5 / 2.75) * t + .75 : t < 2.5 / 2.75 ? 7.5625 * (t -= 2.25 / 2.75) * t + .9375 : 7.5625 * (t -= 2.625 / 2.75) * t + .984375
}

export function getIndicatorDefault(updates = {}) {
	let data = {
		chart: {
			type: "gauge",
			plotBorderWidth: null,
			backgroundColor: "rgba(0,0,0,0)",
			plotBackgroundColor: null,
			plotBackgroundImage: null,
			height: 70,
			spacingBottom: 0,
			spacingTop: 0,
			spacingLeft: 0,
			spacingRight: 0,
			events: {
				render: function () {
					t.summary.meter.textValue = convertTextValueMeter(t.summary.meter.numberValue);
					m.forEach(function (e) {
						if (t.summary.meter.numberValue >= e.min && t.summary.meter.numberValue <= e.max)
							return $(".gauge-meter--su .gauge-meter-background").removeClass("rank-1 rank-2 rank-3 rank-4 rank-5").addClass(e.class),
								$(".gauge-meter--su .gauge-meter-label-item").removeClass("active"),
								void $(".gauge-meter--su .gauge-meter-label-item:nth-child(" + e.index + ")").addClass("active")
					})
				}
			},
			animation: {
				duration: 1500,
				easing: function(t) {
					return t < 1 / 2.75 ? 7.5625 * t * t : t < 2 / 2.75 ? 7.5625 * (t -= 1.5 / 2.75) * t + .75 : t < 2.5 / 2.75 ? 7.5625 * (t -= 2.25 / 2.75) * t + .9375 : 7.5625 * (t -= 2.625 / 2.75) * t + .984375
				}
			}
		},
		credits: {
			enabled: !1
		},
		title: {
			text: ""
		},
		pane: [{
			startAngle: -90,
			endAngle: 90,
			background: null,
			center: ["50%", "105%"],
			size: 140
		}],
		exporting: {
			enabled: !1
		},
		tooltip: {
			enabled: !1
		},
		yAxis: [{
			min: -90,
			max: 90,
			minorTickPosition: "outside",
			tickPosition: "outside",
			labels: {
				rotation: "auto",
				distance: 0,
				style: {
					color: "rgba(0,0,0,0)"
				}
			},
			pane: 0,
			title: "",
			minorTickColor: "rgba(0,0,0,0)",
			lineColor: "rgba(0,0,0,0)",
			tickColor: "rgba(0,0,0,0)"
		}],
		plotOptions: {
			gauge: {
				dataLabels: {
					enabled: !1
				},
				dial: {
					radius: "85%",
					baseLength: "1%",
					rearLength: 0,
					backgroundColor: {
						linearGradient: {
							x1: 1,
							y1: 0,
							x2: 0,
							y2: 0
						},
						stops: [[0, "#fff"], [1, "#000"]]
					},
					baseWidth: 6,
					topWidth: 3
				}
			}
		},
		series: [{
			name: "Summary",
			data: [0],
			yAxis: 0
		}],
		responsive: {
			rules: [{
				condition: {
					maxWidth: 71
				},
				chartOptions: {
					chart: {
						height: 35
					},
					pane: [{
						size: 70
					}]
				}
			}]
		}
	};
	Object.keys(updates).forEach(key => {
		_.set(data, key, updates[key]);
	})
	return data;
}


const {deviceHeight, deviceWidth, deviceVersion} = getDeviceInfo();
let chartHeight = 0;

export function getChartHeight() {
	let {deviceVersion} = getDeviceInfo();
	let t = window.innerHeight
		, e = $("#analysis-wrapper"),
		boxHeader = $(".boxHeader");
	if ("pc" !== deviceVersion && "mobile-landscape" !== deviceVersion) {
		e = $("#analysis-bet-wrapper");
		let outerHeight = e.length ? e.outerHeight() : 0;
		chartHeight = (t - outerHeight - boxHeader.outerHeight());
		return $("#leftContent").css("height", chartHeight);
	}
	let outerHeight = e.length ? e.outerHeight() : 80;
	return chartHeight = (t - outerHeight - boxHeader.outerHeight());
}

export function getTradeChartDefault(updates = {}) {
	let data = {
		rangeSelector: {
			enabled: !1
		},
		credits: {
			enabled: !1
		},
		scrollbar: {
			enabled: !1
		},
		navigator: {
			enabled: !1
		},
		title: {
			text: '<div class="titleChartCandle d-flex align-items-center"><span class="iconETH iconBTC"></span><span class="textPrice">BTC/USDT</span></div>',
			align: "left",
			useHTML: !0
		},
		chart: {
			panning: !1,
			followTouchMove: !1,
			zoomType: "",
			height: chartHeight,
			backgroundColor: "#12013E",
			plotBackgroundImage: "/images/world_map.svg",
			marginRight: "pc" !== deviceVersion ? 70 : 80,
			marginBottom: "pc" !== deviceVersion ? 25 : 30,
			pinchType: '',
			resetZoomButton: {
				theme: {
					display: "none"
				}
			},
			animation: {
				duration: 700
			}
		},
		navigation: {
			bindingsClassName: "tools-container"
		},
		stockTools: {
			gui: {
				enabled: !1
			}
		},
		plotOptions: {
			candlestick: {
				lineColor: "#FC5F5F",
				upLineColor: "#31BAA0",
				pointWidth: "pc" !== deviceVersion ? 9 : 10,
				maxPointWidth: "pc" !== deviceVersion ? 9 : 10
			},
			column: {
				minPointLength: 2,
				pointWidth: "pc" !== deviceVersion ? 11.2 : 11,
				maxPointWidth: "pc" !== deviceVersion ? 11.2 : 11,
				borderWidth: 0,
				pointPadding: 0,
				groupPadding: 0
			},
			series: {
				zIndex: 2,
				states: {
					inactive: {
						opacity: 1
					}
				},
				allowPointSelect: !1,
				point: {
					events: {
						click: function(t) {}
					}
				}
			}
		},
		xAxis: [{
			type: "datetime",
			labels: {
				enabled: !0,
				formatter: function() {
					return moment(this.value).format("mm:ss")
				},
				style: {
					fontSize: 10,
					color: "#707070"
				}
			},
			plotLines: [{
				value: 1580717085410,
				color: "#ffffff",
				width: .75,
				id: "current-pricex",
				zIndex: 1e3,
				dashStyle: "LongDash"
			}],
			lineWidth: 0,
			minorGridLineWidth: 0,
			lineColor: "transparent",
			minorTickLength: 0,
			tickLength: 0
		}],
		yAxis: [
			{
				gridLineColor: "#35275B",
				labels: {
					align: "right",
					x: "pc" !== deviceVersion ? 45 : 50,
					style: {
						color: "#fff",
						fontSize: "11px"
					},
					formatter: function () {
						return this.value / 1000 + 'k';
					}
				},
				height: "85%",
				top: "pc" === deviceVersion ? 0 : null,
				plotLines: [{
					value: 0,
					color: "#ffffff",
					width: .75,
					id: "current-price",
					zIndex: 100,
					label: {
						useHTML: !0,
						text: 0,
						x: "pc" !== deviceVersion ? 60 : 70,
						align: "right",
						style: {
							color: "#fff",
							fontSize: "11px",
							background: "transparent",
							borderRadius: "4px"
						}
					}
				}],
				lineWidth: 0,
				resize: {
					enabled: !0
				}
			}, {
				gridLineColor: "",
				visible: !1,
				title: {
					align: "high",
					text: null,
					rotation: 0,
					y: 0,
					useHTML: !0
				},
				top: "pc" === deviceVersion ? "83.4%" : "85%",
				height: "15%",
				lineWidth: 0,
				offset: 0
			}],
		tooltip: {
			split: !1,
			enabled: !0,
			label: !1,
			animation: !1,
			backgroundColor: "transparent",
			borderColor: "transparent",
			borderWidth: 0,
			stroke: "transparent",
			useHTML: !0,
			style: {
				color: "#fff",
				fontSize: "12px",
				stroke: "transparent"
			},
			formatter: function () {
				return "BTC/USDT" === this.series.name ? '<span style="margin-right: 10px;"><b>O</b>: '
					+ formatDecimalNotRound(this.point.open, "", 2) + '</span><span style="margin-right: 10px;"><b>C</b>: '
					+ formatDecimalNotRound(this.point.close, "", 2) + '</span><span>&nbsp;</span><br/><span style="margin-right: 10px;"><b>H</b>: '
					+ formatDecimalNotRound(this.point.high, "", 2) + '</span><span style="margin-right: 10px;"><b>L</b>: '
					+ formatDecimalNotRound(this.point.low, "", 2) + "</span><span><b>Vol</b>: "
					+ formatDecimalNotRound(this.point.vol, "", 2) + "</span>" : "Volume" === this.series.name ? "<b>Vol</b>: "
					+ formatDecimalNotRound(this.y, "", 2) : "";
			},
			positioner: function () {
				var t = 20
					, e = 60;
				return deviceHeight <= 667 && deviceWidth <= 414 ? (t = 50,
					e = this.chart.plotSizeY - 50) : deviceWidth >= 375 && deviceHeight >= 667 && deviceWidth <= 1024 && (t = 50,
					e = this.chart.plotSizeY - 100),
					{
						x: t,
						y: e
					}
			}
		},
		series: [
			{
				id: "aapl",
				type: "candlestick",
				name: "BTC/USDT",
				color: "#FC5F5F",
				upColor: "#31BAA0",
				point: {},
				data: []
			},
			{
				type: "column",
				name: "Volume",
				point: {},
				data: [],
				yAxis: 1
			},
		]
	}

	Object.keys(updates).forEach(key => {
		_.set(data, key, updates[key]);
	})
	return data;
}


export function getSMADefault(updates = {}){
	let data = {
		name: "sma1",
		type: "sma",
		linkedTo: "aapl",
		color: "#2177FF",
		marker: {
			enabled: false
		},
		zIndex: 3,
		params: {
			index: "0",
			period: 10
		},
	};
	Object.keys(updates).forEach(key => {
		_.set(data, key, updates[key]);
	})
	return data;
}
