import { Component } from 'react';
import {connect} from "react-redux";
import { fetchingUser } from "../../redux/actions";
import {withRouter} from "react-router-dom";
import api from "../../lib/api";

import {
	formatNumber, getUrl, setClassNameCondition,
	showNotification
} from "../../lib/helper";

const TRANSFER_TYPES = {
	phone: {
		name: 'phone',
	},
	username: {
		name: 'username',
	},
};

class Transfer extends Component{
	constructor(props) {
		super(props);
		this.state = {
			...props.memberReducer,
			deposit_value: 0,
			deposit_value_usd: 0,
			is_show_deposit_success: false,
			transfer_type: TRANSFER_TYPES.username.name,
			receiver: '',
			receiver_name: '',
			note: '',
			transfer_value: 0,
			receiver_ok: false,
			otp: '',
			transaction: null
		};
		this.timeoutRequestReceiverName = null;
	}

	componentDidMount() {
		if(this.props.location.state.username){
			this.setState({ receiver: this.props.location.state.username }, this.getOtherMember)
		}
	}

	onChangeTransferMethod = (type) => () => {
		this.setState({ transfer_type: type })
	}

	getOtherMember = () => {
		clearTimeout(this.timeoutRequestReceiverName);
		this.timeoutRequestReceiverName = setTimeout(() => {
			api.getOtherMember({ transfer_type: this.state.transfer_type, receiver: this.state.receiver }).then(({ data, success }) => {
				let { first_name, last_name } = data || {};
				this.setState({ receiver_name: `${first_name || ''} ${last_name || ''}`.trim(), receiver_ok: success});
			});
		}, 300);
	}

	onChangeInput = (type) => (e) => {
		this.setState({ [type]: e.target.value });
		if(type === 'receiver'){
			this.getOtherMember();
		}
	}

	onChangeTransferValue = (e) => {
		let value = parseFloat(e.target.value.toString().replace(/,/g,''));
		this.setState({ transfer_value: value })
	}

	onTransfer = () => {
		let { transfer_value, note, transfer_type, receiver, otp } = this.state;
		api.postTransfer({ transfer_value, note, transfer_type, receiver, otp })
			.then(({ data, message, success }) => {
				message && showNotification({ message, type: success ? 'success' : 'danger' });
				success && data && this.setState({ transaction: data });
				this.props.fetchingUser();
			});
	}

	onSendOTP = () => {
		api.postSendOTP()
			.then(({ message, success, data }) => {
				message && showNotification({ message, type: success ? 'success' : 'danger' });
			});
	}

	render() {
		let { receiver, transfer_type, receiver_name, transfer_value, note, receiver_ok, otp, transaction } = this.state;
		let { min_trans_amount, max_trans_amount } = this.props.appReducer;
		let check_ok = receiver_ok && transfer_value >= min_trans_amount;
 		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-9792f17c="" data-v-b0e45e8c="">
						<div data-v-9792f17c="" className="profileContent">
							<div data-v-9792f17c="" className="container">
								<div data-v-9792f17c="" className="panel panel--security">
									<div data-v-9792f17c="" className="panel-header">
										<h3 data-v-9792f17c="" className="panel-header-title">Chuyển tiền</h3>
									</div>
									<div data-v-9792f17c="" className="panel-body">
										{!transaction ? <div data-v-9792f17c="" className="row">
											<div data-v-9792f17c="" className="col-md-6 col-xs-12">
												<div data-v-9792f17c="" className="row">
													<div data-v-9792f17c="" className="col-12 mb-2">
														<div data-v-9792f17c="" className="form-group">
															<span data-v-9792f17c="" className="labelInput">Chọn phương thức chuyển</span>
															<div className='payment-method'>
																<span style={{width:'100% !important'}} onClick={this.onChangeTransferMethod(TRANSFER_TYPES.username.name)} className={setClassNameCondition(transfer_type === TRANSFER_TYPES.username.name, 'active','','item')}>
																	<i className='fas fa-universal-access' /> Bằng nickname
																</span>
																<span style={{display:'none'}} onClick={this.onChangeTransferMethod(TRANSFER_TYPES.phone.name)} className={setClassNameCondition(transfer_type === TRANSFER_TYPES.phone.name, 'active','','item')}>
																	<i className='fas fa-mobile-alt' /> Bằng SDT
																</span>
															</div>
														</div>
													</div>
													<div data-v-9792f17c="" className="col-12 mb-2">
														<div data-v-9792f17c="" className="form-group">
															<span data-v-9792f17c="" className="labelInput">{transfer_type === TRANSFER_TYPES.username.name ? 'Nickname' : 'Số điện thoại'} người nhận</span>
															<div>
																<input data-v-9792f17c=""
																	   value={receiver}
																	   onChange={this.onChangeInput('receiver')}
																	   type="text" className="form-control mb-2 winput color-white"/>
																<b>{receiver_name}</b>
															</div>
														</div>
													</div>
													<div data-v-9792f17c="" className="col-12 mb-2">
														<div data-v-9792f17c="" className="form-group">
															<span data-v-9792f17c="" className="labelInput">Số tiền chuyển</span>
															<div>
																<input data-v-9792f17c=""
																	   value={formatNumber(transfer_value)}
																	   onChange={this.onChangeTransferValue}
																	   type="text" className="form-control mb-2 winput color-white"/>
															</div>
														</div>
													</div>
													<div data-v-9792f17c="" className="col-12 mb-2">
														<div data-v-9792f17c="" className="form-group">
															<span data-v-9792f17c="" className="labelInput">Ghi chú (không bắt buộc)</span>
															<div>
																<input data-v-9792f17c=""
																	   value={note}
																	   onChange={this.onChangeInput('note')}
																	   type="text" className="form-control mb-2 winput color-white"/>
															</div>
														</div>
													</div>
													
													
													<div data-v-9792f17c="" className="col-12 mt-2">
														<div data-v-9792f17c="" className="form-group text-center">
															<button disabled={!check_ok} onClick={this.onTransfer} className='button wbtn btn-radius btn-large btn-border color-white btn-secondary'>XÁC NHẬN</button>
														</div>
													</div>
												</div>
											</div>
											<div data-v-9792f17c="" className="col-md-6 col-xs-12">
												<div data-v-9792f17c="" className="row" style={{marginTop: '23px'}}>
													<div data-v-9792f17c="" className="col-12 mb-2">
														<h4>QUY ĐỊNH KHI GIAO DỊCH</h4>
														<p>Giao dịch tối thiểu: <b>${formatNumber(min_trans_amount)}</b></p>
														<p>Số tiền tối đa mỗi ngày được chuyển: <b>${formatNumber(max_trans_amount)}</b></p>
													</div>
												</div>
											</div>
										</div>
											: <div className='row'>
												<div  data-v-9792f17c="" className="col-12 text-center">
													<div data-v-110c803e="" className="noticeDeposit" style={{ color: '#31BAA0', marginBottom: '10px', fontSize: '35px' , fontWeight: 'bolder' }}><i className='fas fa-check-circle' /></div>
													<div data-v-110c803e="" className="noticeDeposit" style={{ color: '#31BAA0', marginBottom: '15px', fontSize: '18px' , fontWeight: 'bolder' }}>Giao dịch thành công</div>
													<div data-v-110c803e="" className="noticeDeposit"><span>Mã giao dịch: <b>{transaction.code}</b></span></div>
													<div data-v-110c803e="" className="noticeDeposit"><span>Số tiền chuyển: <b>${formatNumber(transfer_value)}</b></span></div>
													<div data-v-110c803e="" className="noticeDeposit"><span>Tài khoản nhận: <b>{receiver_name} ({receiver})</b></span></div>
													<div data-v-110c803e="" className="noticeDeposit deposit"><span>Nội dung chuyển:</span> <i>{note}</i></div>
												</div>
										</div>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	fetchingUser
})(withRouter(Transfer));

