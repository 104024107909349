import '../../assets/css/profile.css';
import { Component } from 'react';
import { connect } from "react-redux";
import { fetchingUser, fetchingUserSuccess } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import QRCode from 'qrcode';
import api from "../../lib/api";
import Web3 from 'web3';

import {
	copyText,
	formatNumber, getUrl, setClassNameCondition,
	showNotification
} from "../../lib/helper";

const PAYMENT_METHODS = {
	bank: {
		name: 'bank',
		rate: 'vnd_per_usd',
		currency: 'VNĐ'
	},
	usdt: {
		name: 'usdt',
		rate: 'usdt_per_usd',
		currency: 'USDT'
	},
};

class Deposit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.memberReducer,
			deposit_value: 0,
			deposit_value_usd: 0,
			is_show_deposit_success: false,
			payment_method: PAYMENT_METHODS.bank.name,
			web3: null,
			account: null
		};

		this.transferToken = this.transferToken.bind(this);
	}

	onChangeDepositValue = (e) => {
		let { payment_method } = this.state;
		let value = parseFloat(e.target.value.toString().replace(/,/g, ''));
		let rate = this.props.appReducer[PAYMENT_METHODS[payment_method].rate];
		this.setState({ deposit_value_usd: Math.floor(value / rate), deposit_value: value });
	}

	onChangeUSD = (e) => {
		let { payment_method } = this.state;
		let rate = this.props.appReducer[PAYMENT_METHODS[payment_method].rate];
		let value = parseFloat(e.target.value.toString().replace(/,/g, ''));
		this.setState({ deposit_value_usd: value, deposit_value: value * rate });
	}

	onRecharge = () => {
		let { deposit_value_usd, payment_method } = this.state;
		let { usdt_address } = this.props.appReducer;
		api.postRecharge({ amount: deposit_value_usd, payment_method })
			.then(({ data, message, success }) => {
				showNotification({ message, type: success ? 'success' : 'danger' });
				success && this.setState({ is_show_deposit_success: true, deposit_transaction: data.code });
				QRCode.toString(usdt_address).then(qrCode => {
					this.setState({ qr_code: qrCode });
				});
			});
	}

	onChangePaymentMethod = (method) => () => {
		let { deposit_value_usd } = this.state;
		let rate = this.props.appReducer[PAYMENT_METHODS[method].rate];
		this.setState({ payment_method: method, deposit_value: deposit_value_usd * rate })
	}

	onCopyAddress = () => {
		let { usdt_address } = this.props.appReducer;
		copyText(usdt_address);
	}

	

	async componentDidMount() {
		if (typeof window.ethereum !== 'undefined') {
			const web3 = new Web3(window.ethereum);
			try {
				// Request account access if needed
				await window.ethereum.request({ method: 'eth_requestAccounts' });

				// Accounts now exposed
				const accounts = await web3.eth.getAccounts();
				this.setState({ web3, account: accounts[0] });
			} catch (error) {
				console.error('User denied account access or there was an error:', error);
			}
		} else {
			alert('MetaMask is not installed!');
		}
	}

	async transferToken() {
		const { web3, account } = this.state;
		if (!web3 || !account) {
			alert('MetaMask is not connected!');
			return;
		}

		// BEP20 token contract address
		const tokenAddress = '0x55d398326f99059ff775485246999027b3197955';
		// Recipient address
		let { usdt_address } = this.props.appReducer;
		const recipient = "0x25156A4C892514958676F497243B21D8469b1e44"; //usdt_address; // '0x99CE2E70AEC317DDcC76Dcf139e601aCB45d2Ba0';
		//console.log("ADD vi:",recipient)
		
		// ABI of BEP20 token contract (simplified version)
		const tokenABI = [
			// BalanceOf function ABI
			{
				"constant": true,
				"inputs": [
					{
						"name": "_owner",
						"type": "address"
					}
				],
				"name": "balanceOf",
				"outputs": [
					{
						"name": "balance",
						"type": "uint256"
					}
				],
				"type": "function"
			},
			// Transfer function ABI
			{
				"constant": false,
				"inputs": [
					{
						"name": "_to",
						"type": "address"
					},
					{
						"name": "_value",
						"type": "uint256"
					}
				],
				"name": "transfer",
				"outputs": [
					{
						"name": "",
						"type": "bool"
					}
				],
				"type": "function"
			}
		];

		const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);

		try {
			// Check the balance
			const balance = await tokenContract.methods.balanceOf(account).call();
			const balanceInEther = web3.utils.fromWei(balance, 'ether');

			let { deposit_value_usd } = this.state;

			if (deposit_value_usd < 50) {
				alert('You need at least 50 tokens to proceed.');
				return;
			}

			if (parseFloat(balanceInEther) < deposit_value_usd) {
				alert('Insufficient balance');
				return;
			}

			// Amount of tokens to transfer (in wei)
			const amount = web3.utils.toWei(deposit_value_usd.toString(), 'ether'); // Adjust the amount accordingly

			// Perform the transfer
			const transfer = await tokenContract.methods.transfer(recipient, amount).send({ from: account });
			console.log('Transfer successful:', transfer);
			console.log('Transfer successful tx:', transfer.transactionHash);
			
			await this.postDeposit(transfer.transactionHash,amount,account);
			showNotification({ message: 'Nạp tiền thành công', type: 'success' });
		} catch (error) {
			showNotification({ message: 'Nạp tiền lỗi', type: 'danger' });
		}
	}
	postDeposit = async (txhash,amount,account) => {
		let { usdt_address } = this.props.appReducer;
		let { id, username, deposit_value, deposit_transaction, payment_method} = this.state;
		console.log("GET infor: Id member:"+id+" deposit_username:"+username+" Deposit value:",deposit_value+" deposit_transaction:"+deposit_transaction+" payment_method:"+payment_method);
		
		try {
			fetch('https://api-trading.net/api.php?obj=post-api', {
				method: 'POST',
				body: JSON.stringify({
					id: id,
					username: username,					
					code_transaction: deposit_transaction,
					from: account,
					to:	usdt_address,
					payment_method: payment_method,					
					amount_deposit: deposit_value,
					amount_value: amount,
					txhash: txhash
				}),
				headers: {
					'Content-type': 'application/json; charset=UTF-8',
				}
			})
			.then(function (response) {
				return response.json()
			})
			.then(function (data) {
				console.log(data)
			}).catch(error => console.error('Error:', error));
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		
	}
	render() {
		let { deposit_value, deposit_value_usd, is_show_deposit_success, deposit_transaction, payment_method, qr_code } = this.state;
		let { minimum_deposit, bank_data, usdt_address } = this.props.appReducer;
		bank_data = bank_data.replace(/\r?\n/g, '<br/>');
		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-9792f17c="" data-v-b0e45e8c="">
						<div data-v-9792f17c="" className="profileContent">
							<div data-v-9792f17c="" className="container">
								<div data-v-9792f17c="" className="panel panel--security">
									<div data-v-9792f17c="" className="panel-header">
										<h3 data-v-9792f17c="" className="panel-header-title">Nạp tiền</h3>
									</div>
									<div data-v-9792f17c="" className="panel-body">
										{!is_show_deposit_success ? <>
											<div data-v-9792f17c="" className="row mb-5">
												<div data-v-9792f17c="" className="col-md-3 col-xs-12">
													<div data-v-9792f17c="" className="form-group">
														<span data-v-9792f17c="" className="labelInput">Chọn phương thức nạp</span>
														<div className='payment-method'>
															<span onClick={this.onChangePaymentMethod(PAYMENT_METHODS.bank.name)} className={setClassNameCondition(payment_method === PAYMENT_METHODS.bank.name, 'active', '', 'item')}>Nạp bằng VND</span>
															<span onClick={this.onChangePaymentMethod(PAYMENT_METHODS.usdt.name)} className={setClassNameCondition(payment_method === PAYMENT_METHODS.usdt.name, 'active', '', 'item')}>Nạp bằng USDT</span>
														</div>
													</div>
												</div>
												<div data-v-9792f17c="" className="col-md-3 col-xs-12">
													<div data-v-9792f17c="" className="form-group">
														<span data-v-9792f17c="" className="labelInput">Số tiền muốn nạp ({PAYMENT_METHODS[payment_method].currency})</span>
														<input data-v-9792f17c=""
															placeholder="Nhập số tiền muốn nạp"
															value={formatNumber(deposit_value)}
															onChange={this.onChangeDepositValue}
															type="text" className="form-control mb-2 winput input-deposit" />
													</div>
												</div>
												<div data-v-9792f17c="" className="col-md-3 col-xs-12">
													<div data-v-9792f17c="" className="form-group deposit-usd">
														<span data-v-9792f17c="" className="labelInput">Số tiền muốn nhận (USD)</span>
														<span className='usd-symbol'>$</span>
														<input
															placeholder="Nhập số tiền muốn nhận (USD)"
															data-v-9792f17c=""
															value={formatNumber(deposit_value_usd)}
															onChange={this.onChangeUSD}
															type="text"
															className="form-control mb-2 winput input-deposit" />
													</div>
												</div>
												<div data-v-9792f17c="" className="col-md-3 col-xs-12">
													<button data-v-9792f17c="" style={{ marginTop: '25px', height: '43px', width: '100%' }}
														type="button"
														onClick={this.onRecharge}
														className="button wbtn btn-radius btn-large btn-border color-white btn-secondary">Yêu Cầu Nạp
													</button>
												</div>
											</div>
											<div data-v-9792f17c="" className="row">
												<div data-v-9792f17c="" className="col-12">
													<p data-v-110c803e="" className="noticeDeposit">
														<span data-v-110c803e="">Giá trị nhận tối thiểu là: ${formatNumber(minimum_deposit)} ({formatNumber(minimum_deposit * this.props.appReducer[PAYMENT_METHODS[payment_method].rate])} {PAYMENT_METHODS[payment_method].currency})</span>
														<br />
														<span data-v-110c803e="">( Tỉ giá quy đổi hiện tại: $1 = {formatNumber(this.props.appReducer[PAYMENT_METHODS[payment_method].rate])} {PAYMENT_METHODS[payment_method].currency} )</span>
													</p>
												</div>
											</div>
										</>
											: <div className='row mb-5'>
												{payment_method === PAYMENT_METHODS.bank.name ? <div data-v-9792f17c="" className="col-12">
													<div data-v-110c803e="" className="noticeDeposit" style={{ color: '#31BAA0', marginBottom: '20px', fontSize: '23px', fontWeight: 'bolder', textTransform: 'capitalize' }}>Gửi yêu cầu thành công</div>
													<div data-v-110c803e="" className="noticeDeposit deposit-change-font"><span>Vui lòng chuyển khoản đúng <b>{formatNumber(deposit_value)}VNĐ</b> vào tài khoản sau!</span></div>
													<div data-v-110c803e="" className="noticeDeposit deposit highlight-text" dangerouslySetInnerHTML={{ __html: bank_data }} />
													<div data-v-110c803e="" className="noticeDeposit deposit deposit-change-font" style={{ margin: '25px 0px' }}>
														<span>Nội dung chuyển:</span>
														<b className='highlight-text' style={{ marginLeft: '4px' }}>{deposit_transaction}</b>
														<span className='btn-copy' onClick={() => copyText(deposit_transaction)}>Copy</span>
													</div>
													<div data-v-110c803e="" className="noticeDeposit deposit deposit-change-font">
														<span style={{ color: 'red' }}>Chú ý: Vui lòng ghi đúng nội dung chuyển khoản bên trên để tránh phát sinh lỗi!</span><br />
														<span style={{ color: 'red' }}>( Vui lòng gọi hotline sau 10phút chuyển khoản nếu vẫn chưa lên ví )</span>
													</div>
												</div> :
													<div data-v-9792f17c="" className="col-12">
														<div data-v-110c803e="" className="noticeDeposit" style={{ color: '#31BAA0', marginBottom: '7px', fontSize: '18px', fontWeight: 'bolder' }}>Gửi yêu cầu thành công</div>
														<div data-v-110c803e="" className="noticeDeposit"><span>Vui lòng chuyển khoản đúng <b>{formatNumber(deposit_value)} USDT</b> vào địa chỉ ví sau!</span></div>
														<div data-v-110c803e="" className="noticeDeposit deposit">
															<span>USDT address:</span>
															<div className='copy-usdt'>
																<input type="text" disabled={true} className="form-control mb-2 winput input-deposit" value={usdt_address} />
																<span onClick={this.onCopyAddress} className='pe pe-7s-copy-file' />
															</div>
														</div>
														<div data-v-110c803e="" >
															<div style={{ maxHeight: '200px', maxWidth: '200px' }} dangerouslySetInnerHTML={{ __html: qr_code }} />

														</div>
														<div data-v-110c803e="" className="noticeDeposit wallet_connect">
															<span>Address wallet connect:</span>
															<div className="address-connect">
																<div data-v-d201240a="" class="leftControl">
																	<input type="text" disabled={true} className="form-control mb-2 winput input-deposit" value={this.state.account} style={{paddingLeft:'0px;'}}/>
																</div>
															</div>
														</div>

														<div class="box-tranfer">
															<button class="btn btn-success" onClick={this.transferToken}>Transfer USDT BEP20</button>
														</div>
													</div>}
											</div>}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess
})(withRouter(Deposit));

